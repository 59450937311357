<template>
  <v-container class="fill-height">
    <v-row align="center" justify="center">
      <v-sheet class="pa-3 text-center" color="white" elevation="2">
        <h1 class="text-h3">Login</h1>
        <p>You can login using your Google or Facebook account.</p>
        <div>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn @click="doLogin('GoogleAuthProvider')" :class="{ 'my-2': !isOnPC }" :x-large="!isOnPC" large width="150" class="mx-2" v-on="on" dark color="#DB4437"><v-icon class="mr-3">mdi-google</v-icon> Google</v-btn>
            </template>
            <span>Lanjut dengan Google</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn @click="doLogin('FacebookAuthProvider')" :class="{ 'my-2': !isOnPC }" :x-large="!isOnPC" large width="150" class="mx-2" v-on="on" dark color="#3b5998"><v-icon class="mr-3">mdi-facebook</v-icon> Facebook</v-btn>
            </template>
            <span>Lanjut dengan Facebook</span>
          </v-tooltip>
        </div>
      </v-sheet>
    </v-row>
  </v-container>
</template>

<script>
import firebase from '@/plugins/firebase'

export default {
  name: 'LoginView',

  methods: {
    doLogin (providerMethodName) {
      var provider = new firebase.auth[providerMethodName]()
      // provider.addScope('email')
      // provider.addScope('profile')

      // firebase.auth().signInWithPopup(provider).then(result => {
      //   this.$store.commit('auth/setUser', result.user)
      //   this.$store.commit('auth/setToken', result.token)
      // })
      firebase.auth().signInWithRedirect(provider)
    }
  }
}
</script>
